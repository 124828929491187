import store from './store';
import AutCompleteComponent from '@components/AutoComplete/AutoComplete.vue';
import FullLoaderComponent from '@components/Loader/FullLoaderComponent.vue';
import { Vue, axios, isset } from '@master';

const header = new Vue({
    el: '#navigation',
    store,
    data() {
        return {
            isActive: false,
            searchId: null,
            businessId: null,
            showResourceMenu: false,
            showProfileMenu: false,
            officeIds: [],
            bookingImport: {
                processing: false,
                officeId: '',
                recordLocator: ''
            }
        }
    },
    methods: {
        openLogin() {
            this.isActive = false;
            this.openLoginModal(($event) => window.location.reload())
        },
        goToBooking(booking) {
            if(booking.id != null) {
                window.location.href = `/admin/bookings/${booking.id}`;
            }
        },
        goToBusiness(business) {
            if(business.id != null) {
                window.location.href = `/admin/businesses/${business.id}`;
            }
        },
        async importBooking() {
            this.bookingImport.processing = true;
            try {
                const valid = await this.$validator.validate();
                if(valid != true) {
                    this.bookingImport.processing = false;
                    return;
                }
                const payload = {
                    officeId: this.bookingImport.officeId,
                    recordLocator: this.bookingImport.recordLocator
                };
                const booking = (await axios.post('/admin/bookings/import', payload)).data;
                window.location.href = `/admin/bookings/${booking.id}`;
            } catch (error) {
                this.swalError(isset(error.data) ? error.data.message : error);
            }
            this.bookingImport.processing = false;
        },
        toggleSavedItineraries() {
            this.$store.dispatch('savedItineraries/toggleSavedItineraries')
            this.isActive = false;
        }
    },
    computed: {
        viewSavedItineraries() {
            return this.$store.getters['savedItineraries/getShowSavedItineraries'];
        },
        savedItineraryCount() {
            return this.$store.getters['savedItineraries/getSavedItineraryCount']
        }
    },
    components: {
        'auto-complete': AutCompleteComponent,
        'full-loader': FullLoaderComponent
    }
});
